<template>
    <div class="Sku" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" v-model="form" size="small" label-width="80px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="关键字">
                            <el-input placeholder="名称/条码" v-model="form.search" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="商品类型">
                            <el-select placeholder="商品类型" v-model="form.type">
                                <el-option label="请选择" value="" />
                                <el-option label="标品" value="0" />
                                <el-option label="非标品" value="1" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="类目">
                            <ef-category v-model="form.categoryCode" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 10px">
                    <el-col :span="8">
                        <el-form-item label="品牌">
                            <ef-brand v-model="form.brandCode" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" v-if="$store.state.session.code == 'TRSSTF00000000000001'">
                        <el-form-item label="机构组">
                            <el-select placeholder="门店名称" v-model="form.deptGroupCode" filterable>
                                <el-option label="请选择" value="" />
                                <el-option
                                    v-for="dept in profitDistributionMechanism"
                                    :label="dept.name"
                                    :value="dept.code"
                                    :key="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="searchHandleQuery" size="small" v-if="hasPrivilege('menu.goods.sku.open')"
                >查询
            </el-button>
            <el-button type="primary" @click="handleCreate" size="small" v-if="hasPrivilege('menu.goods.sku.create')">
                新建
            </el-button>
            <ef-export-button
                :url="'/goods/sku/exportNewWay'"
                v-if="hasPrivilege('menu.goods.sku.export')"
                :form="form"
                @clickButtonGetSelectedCodes="getSelectedCodes"
            ></ef-export-button>
            <!--            <el-button type="primary" @click="handleExport" size="small" v-if="hasPrivilege('menu.goods.sku.export')">
                导出(旧)
            </el-button>-->
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <el-card shadow="never" style="margin-top: 8px" align="left">
            <el-table
                id="printMe"
                border
                stripe
                style="width: 100%"
                ref="table"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="440"
            >
                <el-table-column
                    :label="columns.index.name"
                    type="index"
                    :width="columns.index.width"
                    align="center"
                    fixed="left"
                    v-if="columns.index.show"
                />
                <el-table-column
                    v-if="showColumn('name')"
                    prop="name"
                    :label="columns.name.name"
                    :width="columns.name.width"
                    fixed="left"
                />
                <el-table-column
                    v-if="showColumn('bars')"
                    prop="bars"
                    key="bars"
                    :label="columns.bars.name"
                    :width="columns.bars.width"
                >
                    <template slot-scope="scope">
                        <div v-for="b in scope.row.bars" :key="b">{{ b }}</div>
                    </template>
                </el-table-column>
                <el-table-column
                    v-if="showColumn('specs')"
                    prop="specs"
                    :label="columns.specs.name"
                    :width="columns.specs.width"
                />
                <el-table-column
                    v-if="showColumn('type')"
                    prop="type"
                    key="type"
                    :label="columns.type.name"
                    :width="columns.type.width"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.type | goodsType }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    v-if="showColumn('category')"
                    prop="category"
                    :label="columns.category.name"
                    :width="columns.category.width"
                />
                <el-table-column
                    v-if="showColumn('brand')"
                    prop="brand"
                    :label="columns.brand.name"
                    :width="columns.brand.width"
                />
                <el-table-column
                    v-if="showColumn('unit')"
                    prop="unit"
                    :label="columns.unit.name"
                    :width="columns.unit.width"
                />
                <el-table-column
                    v-if="showColumn('expirationDate')"
                    prop="expirationDate"
                    :label="columns.expirationDate.name"
                    :width="columns.expirationDate.width"
                />
                <el-table-column
                    v-if="showColumn('creator')"
                    prop="creator"
                    :label="columns.creator.name"
                    :width="columns.creator.width"
                />
                <el-table-column
                    v-if="showColumn('createTime')"
                    prop="createTime"
                    :label="columns.createTime.name"
                    :width="columns.createTime.width"
                />
                <el-table-column
                    v-if="showColumn('operate')"
                    prop="operate"
                    key="operate"
                    :label="columns.operation.name"
                    :width="columns.operation.width"
                    min-width="275"
                    header-align="center"
                >
                    <template slot-scope="scope">
                        <el-button size="mini" @click="rowDetail(scope.row)" v-if="hasPrivilege('menu.goods.sku.open')">
                            查看
                        </el-button>
                        <el-button
                            size="mini"
                            type="warning"
                            @click="rowEdit(scope.row)"
                            v-if="hasPrivilege('menu.goods.sku.edit')"
                            >编辑
                        </el-button>
                        <el-button
                            size="mini"
                            type="danger"
                            @click="rowDelete(scope)"
                            v-if="hasPrivilege('menu.goods.sku.delete')"
                            >删除
                        </el-button>
                        <el-button
                            size="mini"
                            type="success"
                            @click="showSkuHistory(scope.row.code)"
                            v-if="hasPrivilege('menu.goods.sku.open')"
                            >历史
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <ef-pagination
                :total="total"
                @pageChange="pageChange"
                :default-limit="form.limit"
                :current-page="form.page"
            />
        </el-card>
        <sku-history ref="skuHistory" />
    </div>
</template>

<script>
import Util from 'js/Util';
import EfCategory from 'components/EfCategory';
import EfBrand from 'components/EfBrand';
import EfPagination from 'components/EfPagination';
import SkuHistory from './sku/SkuHistory';
import UrlUtils from 'js/UrlUtils';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';
import EfExportButton from 'components/EfExportButton';

export default {
    name: 'Sku',
    components: { EfCategory, EfBrand, EfPagination, SkuHistory, CheckTableShowColumnDialog, EfExportButton },
    mixins: [CheckTableShowColumn],
    data() {
        return {
            form: {
                search: '',
                categoryCode: null,
                brandCode: null,
                type: '',
                deptGroupCode: null,
                page: 1,
                limit: 50,
            },
            tableData: [],
            total: 0,
            columns: {
                index: { name: '序号', show: true, width: 60 },
                name: { name: '物品名称', width: 180, show: true },
                bars: { name: '条码', width: 120, show: true },
                specs: { name: '规格', width: 100, show: true },
                type: { name: '类型', width: 80, show: true },
                category: { name: '类目', width: 120, show: true },
                brand: { name: '品牌', width: 120, show: true },
                unit: { name: '单位(小)', width: 80, show: true },
                expirationDate: { name: '保质期', width: 100, show: true },
                creator: { name: '创建人', width: 100, show: true },
                createTime: { name: '创建时间', width: 140, show: true },
                operation: { name: '操作', width: 275, show: true },
            },
            exportDisabled: false,
            profitDistributionMechanism: [],
        };
    },
    beforeMount() {
        this.$http.get(`/system/deptGroup/staffManagedAllDeptGroups`).then((rsp) => {
            this.profitDistributionMechanism = rsp.data.data;
        });
    },
    mounted() {
        this.handleQuery();
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    methods: {
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        pageChange(page, limit) {
            this.form.page = page;
            this.form.limit = limit;
            this.handleQuery();
        },
        searchHandleQuery() {
            this.form.page = 1;
            this.handleQuery();
        },
        async handleQuery() {
            const rst = await this.$efApi.skuApi.pageExtend(this.form);
            this.tableData = rst.data;
            this.total = rst.count;
        },
        handleCreate() {
            Util.nameJump(this, 'menu.goods.sku.batchCreate', ['商品管理', '商品基本信息管理', '批量新建SKU']);
        },
        handleExport() {
            const codes = (this.$refs.table.selection || []).map((e) => e.code);

            UrlUtils.Export(this, 'sku', '/goods/sku/export', this.form, codes);
        },
        getSelectedCodes(callback) {
            const selectedCodes = (this.selectedCodes = (this.$refs.table.selection || []).map((e) => e.code));
            callback(selectedCodes);
        },
        rowDetail(row) {
            Util.nameJump(this, 'menu.goods.sku.detail', ['商品管理', '商品基本信息管理', 'SKU详情'], {
                form: row,
                code: row.code,
            });
        },
        rowEdit(row) {
            Util.nameJump(this, 'menu.goods.sku.edit', ['商品管理', '商品基本信息管理', '编辑SKU'], {
                form: row,
                code: row.code,
            });
        },
        async rowDelete(scope) {
            const row = scope.row;
            //校验是否能够删除
            const goodsRst = await this.$efApi.goodsApi.goodsList({ skuCode: row.code });
            if (goodsRst.length > 0) {
                this.$message.error('该商品基本信息已被使用，不可删除');
                return false;
            }
            UrlUtils.DeleteRemote(this, '/goods/sku/delete', row.code);
        },
        showSkuHistory(code) {
            this.$refs.skuHistory.show(code);
        },
    },
};
</script>

<style scoped>
.Sku .el-form-item {
    margin-bottom: 0;
}

.Sku .el-table th.gutter {
    display: table-cell;
}
</style>
